import bioJoshua from "./img/bio/joshua.jpg";
import bioJulien from "./img/bio/julien.jpg";
import bioMatthias from "./img/bio/matthias.jpg";
import bioWesley from "./img/bio/wesley.jpg";
import bioKatty from "./img/bio/katty.jpg";
import bioMohamed from "./img/bio/mohamed.jpg";
import bioThibault from "./img/bio/thibault.jpg";
import bioRamin from "./img/bio/ramin.jpg";
import bioLucas from "./img/bio/lucas.jpg";
import bioHadrien from "./img/bio/hadrien.jpg";
import bioTodd from "./img/bio/todd.jpg";
import bioRay from "./img/bio/ray.jpg";
import bioQuin from "./img/bio/quin.jpg";
import bioKyle from "./img/bio/kyle.jpg";
import bioTim from "./img/bio/tim.jpg";
import bioMohit from "./img/bio/mohit.jpg";
import bioPeter from "./img/bio/peter.jpg";
import React from "react";
import "./People.scss";

const MUGSHOT_DIM = "90px";
const MUGSHOT_PADDING = "3px";

const Person: React.FC<{
  mugshot: string;
  name: string;
  title: React.ReactNode;
  children?: React.ReactNode;
}> = ({ mugshot, name, title, children }) => {
  return (
    <div className="person">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <div
          className="mugshot"
          style={{
            flex: `0 0 ${MUGSHOT_DIM}`,
            width: MUGSHOT_DIM,
            height: MUGSHOT_DIM,
            padding: MUGSHOT_PADDING,
            borderRadius: "50%",
            border: "1px solid black",
            margin: "0 auto",
          }}
        >
          <img
            src={mugshot}
            style={{
              width: `calc(${MUGSHOT_DIM} - 2 * ${MUGSHOT_PADDING} - 2 * 1px)`,
              height: `calc(${MUGSHOT_DIM} - 2 * ${MUGSHOT_PADDING} - 2 * 1px)`,
              objectFit: "cover",
            }}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <h3 className="title is-5">{name}</h3>
          <h3 className="subtitle is-5">{title}</h3>
        </div>
      </div>
      <div
        className="content"
        style={{
          marginTop: "1rem",
          // marginLeft: `calc(${MUGSHOT_DIM} + 1rem)`
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const People = React.forwardRef<HTMLDivElement, {}>((_, ref) => {
  return (
    <div>
      <h1
        ref={ref}
        className="title is-1"
        style={{
          paddingTop: "6rem",
          textAlign: "center",
          marginBottom: "4rem",
        }}
      >
        Meet our scientists
      </h1>
      <section className="persons">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: "5rem",
            rowGap: "3rem",
          }}
        >
          <Person
            mugshot={bioJoshua}
            name="JOSHUA LANIADO, Ph.D."
            title="Founder"
          >
            Joshua holds a Ph.D. in Molecular Biology from UCLA. During his time
            at UCLA he directed his attention to the computational design and
            experimental characterization of protein nanomaterials.
          </Person>

          <Person
            mugshot={bioPeter}
            name="PETER BOWERS, Ph.D."
            title="Head of Research"
          >
            Peter comes with 20+ years of leadership in the discovery of
            biotherapeutics. He has provided oversight for therapeutic programs
            yielding 11 approved and clinical stage antibodies including
            Imsidolimab, Dostarlimab, Cobolimab, Etokimab, Encelimab, and
            Rosnilimab. He is the author of over 40 papers and patents in the
            field of protein engineering and antibody discovery.
          </Person>

          <Person
            mugshot={bioJulien}
            name="JULIEN JORDA, Ph.D."
            title="Head of Computational Biology"
          >
            Julien holds a Ph.D. in Computational Biology and Bioinformatics
            from Montpellier University. He further specialized as a
            postdoctoral researcher at UCLA where he developed strategies for
            predicting and designing protein assemblies and tackled theoretical
            problems in structural biology.
          </Person>

          <Person
            mugshot={bioMohamed}
            name="MOHAMED EL HIBOURI"
            title="Head of AI"
          >
            Mohamed has a double M.S. in Management Science and Engineering from
            Columbia University and in General Engineering from ENSAM ParisTech.
            His research interests include advanced Deep Learning, Analytical
            Modeling and Business Intelligence.
          </Person>

          <Person
            mugshot={bioHadrien}
            name="HADRIEN CHAUVIN"
            title="Head of Engineering"
          >
            Hadrien holds a M.S. in Systems Biology from Imperial College London
            and a M.S. in Mathematics from Ecole Centrale Paris. Previously, he
            built clinical trial and genomic platforms for various
            pharmaceutical companies and worked on NLP and expert system
            products.
          </Person>

          <Person
            mugshot={bioMatthias}
            name="MATTHIAS MALAGO"
            title="Software Engineer"
          >
            Matthias holds a M.S. in Systems Biology from Imperial College
            London. He works at the intersection of the research teams and
            engineering team. He develops tools to address the researchers'
            needs and incorporates their technology, algorithms, and models into
            Pythia's product.
          </Person>

          <Person
            mugshot={bioThibault}
            name="THIBAULT DUPLAY"
            title="AI Research Scientist"
          >
            Thibault holds a M.S. in Operations Research from Columbia
            University and a M.S. in Mathematical Statistics from ENSAE. His
            research interests include advanced Mathematics and their
            application to Deep Learning.
          </Person>

          <Person
            mugshot={bioRamin}
            name="RAMIN ANSARI, Ph.D."
            title="AI Research Scientist"
          >
            Ramin holds a Ph.D. in Computational Chemistry from University of
            Michigan. During his Ph.D. studies, he focused on applying
            computational chemistry and Deep Learning techniques for various
            molecular property prediction projects.
          </Person>

          <Person
            mugshot={bioLucas}
            name="LUCAS ZANINI"
            title="AI Research Scientist"
          >
            Lucas holds a M.S. in Mathematical Statistics from ENSAE Paris and a
            M.S. in Machine Learning from ENS Paris-Saclay. His interests
            include Natural Language Processing, Generative Modeling and
            Computational Statistics.
          </Person>

          <Person
            mugshot={bioWesley}
            name="WESLEY BOTELLO-SMITH, Ph.D."
            title="Computational Biology Research Scientist"
          >
            Wesley holds a Ph.D. in Chemistry from University of California
            Irvine. His postdoctoral work at the Western University of Health
            Sciences in Pomona included a number of modeling, simulation, and
            computational structural biology projects.
          </Person>

          <Person
            mugshot={bioKatty}
            name="KATTY POLYAK"
            title="Software Engineer"
          >
            Katty holds a B.S. in Bioengineering from Binghamton University.
            Previously, she developed large-scale backend systems at Spotify and
            conducted Computational Biology research at Cold Spring Harbor
            Laboratory. She currently engineers tools that enhance efficiency
            for Pythia's AI and Computational Biology teams.
          </Person>

          <Person
            mugshot={bioQuin}
            name="QUIN HELFRICK"
            title="Software Engineer"
          >
            Quin holds a B.S. in Computer Engineering from the University of
            Virginia. He works to ensure that the researchers' cutting edge
            algorithms and models are seamlessly integrated into Pythia's
            product.
          </Person>

          <Person
            mugshot={bioKyle}
            name="KYLE MEADOR, Ph.D."
            title="Computational Biology Research Scientist"
          >
            Kyle earned his Ph.D. in Biochemistry at the University of
            California Los Angeles, applying protein design to create new
            nanomaterials including protein cages and scaffolds for high
            resolution election microscopy.
          </Person>

          <Person
            mugshot={bioTim}
            name="TIM-HENRIK BUELLES, Ph.D."
            title="AI Research Scientist"
          >
            Tim holds a Ph.D. in Mathematics from ETH Zürich. He was a
            postdoctoral scholar at Caltech where he worked on the theory and
            applications of Geometric Deep Learning and Representation Learning
            on graphs and manifolds.
          </Person>

          <Person
            mugshot={bioMohit}
            name="MOHIT YADAV, Ph.D."
            title="AI Research Scientist"
          >
            Mohit holds a Ph.D. in Computer Science from the University of
            Massachusetts Amherst. His research concentrates on the fundamentals
            of Machine Learning and its various applications, with a focus on
            enhancing computational efficiency. His expertise spans Gaussian
            processes, Deep Learning, and NLP.
          </Person>

          <div style={{ flexGrow: 1, flexBasis: 0, minWidth: "40%" }}></div>
        </div>

        <h1
          className="title is-1"
          style={{
            marginTop: "4rem",
            textAlign: "center",
            marginBottom: "4rem",
          }}
        >
          Our Scientific Advisory Board
        </h1>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "4rem" }}>
          <Person
            mugshot={bioTodd}
            name="TODD O. YEATES, Ph.D."
            title="Scientific Advisor"
          >
            Todd Yeates, Ph.D., is currently a Distinguished Professor at UCLA
            in the Department of Chemistry and Biochemistry, and the Director of
            the Institute for Genomics and Proteomics.
            <br />
            <br />
            Todd earned his Ph.D. at UCLA working on the atomic structures of
            early transmembrane proteins, and then did postdoctoral training on
            virus structure at The Scripps Research Institute. His research
            spans computational biology, structural biology, and protein design.
            <br />
            <br />
            His equations for analyzing complex diffraction data are widely used
            in modern crystallographic methods. In the area of cryo-electron
            microscopy, his laboratory has developed a general solution to the
            problem of imaging small proteins using designed binding scaffolds.
            <br />
            <br />
            In 2001, his laboratory pioneered principles and methods for
            designing novel protein cages, creating the first such molecular
            assemblies. Designed protein cages are emerging as powerful
            platforms for diverse applications in biotechnology, from vaccine
            design to T-cell therapeutics.
            <br />
            <br />
            Dr. Yeates has authored over 200 peer-reviewed articles.
          </Person>
          <Person
            mugshot={bioRay}
            name="RAYMOND SCHINAZI, Ph.D."
            title="Scientific Advisor"
          >
            Raymond Schinazi, Ph.D., Hon DSc, FAASLD, is the Frances Winship
            Walters Professor of Pediatrics and Director of the Laboratory of
            Biochemical Pharmacology at Emory University (which he joined in
            1978).
            <br />
            <br />
            Raymond holds a Ph.D. in Chemistry from the University of Bath,
            England. A world leader in nucleoside chemistry, he is best known
            for his pioneering work on HIV, HBV and HCV drugs including FTC
            (emtricitabine), LdT (telbivudine), and sofosbuvir (Sovaldi). In the
            U.S.A., more than 94% of HIV-infected individuals on combination
            therapy take at least one of the drugs he invented. He is also the
            inventor of the use of baricitinib, a JAK inhibitor, for the
            treatment of COVID-19 patients.
            <br />
            <br />
            Dr. Schinazi has authored over 600 peer-reviewed articles and 7
            books. He holds over 100 issued US patents, which have resulted in
            28 New Drug Applications (NDA).
            <br />
            <br />
            Dr. Schinazi is a Charter Fellow of the National Academy of
            Inventors. He is the recipient of numerous awards, including: 2018
            France's highest civilian honor, the Légion D'honneur, for saving
            millions of lives globally. In 2020, he became Fellow of the
            American Association for the Advancement of Science (AAAS), and in
            2021, he received the AASLD Distinguished Achievement Award.
          </Person>
        </div>
      </section>
    </div>
  );
});
